













import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'AddMembersLoading' })
export default class AddMembersLoading extends Vue {
}
